import WebServiceRequest from "../Api/WebServiceRequest";

class CreateDynamicPages extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("DynamicPages/Create");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

class DynamicPagesGetByType extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("DynamicPages/GetByType");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class UpdateDynamicPages extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("DynamicPages/UpdateSiteSetting");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

export {
    CreateDynamicPages,
    DynamicPagesGetByType,
    UpdateDynamicPages,
}