<template>
  <b-overlay :show="showOverlay">

    <div v-if="pageData && pageData.value">
      <b-card>
        <language-picker
            @getLanguage="setLanguage"
        ></language-picker>
      </b-card>
      <b-card>
        <b-row>
          <b-col class="mb-1" cols="12">
            <h1>Edit contents</h1>
          </b-col>

          <b-col md="12">
            <b-form-group
                class="mb-2"
                label="description"
                label-for="description"
            >
              <b-form-textarea
                  id="description"
                  v-model="pageData.value.description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="12">
            <h2>Social media links</h2>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="youtube link"
                label-for="youtube"
            >
              <b-form-input
                  id="youtube"
                  v-model="pageData.value.youtube"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="facebook link"
                label-for="facebook"
            >
              <b-form-input
                  id="facebook"
                  v-model="pageData.value.facebook"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="telegram link"
                label-for="telegram"
            >
              <b-form-input
                  id="telegram"
                  v-model="pageData.value.telegram"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="linkedin link"
                label-for="linkedin"
            >
              <b-form-input
                  id="linkedin"
                  v-model="pageData.value.linkedin"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="linkedin phone number"
                label-for="linkedinNumber"
            >
              <b-form-input
                  id="linkedinNumber"
                  v-model="pageData.value.linkedinNumber"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="instagram link"
                label-for="instagram"
            >
              <b-form-input
                  id="instagram"
                  v-model="pageData.value.instagram"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="whatsapp link"
                label-for="whatssapp"
            >
              <b-form-input
                  id="whatssapp"
                  v-model="pageData.value.whatssapp"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="whatsapp phone number"
                label-for="whatssappNumber"
            >
              <b-form-input
                  id="whatssappNumber"
                  v-model="pageData.value.whatssappNumber"
                  dir="auto"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="email"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="pageData.value.email"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="phone number"
                label-for="phone"
            >
              <b-form-input
                  id="phone"
                  v-model="pageData.value.phone"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="first mobile number"
                label-for="mobileOne"
            >
              <b-form-input
                  id="mobileOne"
                  v-model="pageData.value.mobileOne"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="second mobile number"
                label-for="mobileTwo"
            >
              <b-form-input
                  id="mobileTwo"
                  v-model="pageData.value.mobileTwo"
                  dir="auto"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                class="mb-2"
                label="address"
                label-for="address"
            >
              <b-form-input
                  id="address"
                  v-model="pageData.value.address"
              />
            </b-form-group>
          </b-col>

          <b-col class="my-2" cols="12">
            <b-button class="btn" type="button" variant="primary" @click="updateFooter">Submit changes</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCarousel,
  BCarouselSlide,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {DynamicPagesGetBy, DynamicPagesGetByType, UpdateDynamicPages} from "@/libs/Api/DynamicPages";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";

export default {
  name: "footer",
  title: "footer",
  data() {
    return {
      showOverlay: false,
      languageList:[],
      rawHTML: '',
      languageId:1,
      pageData: null,
    }
  },
  watch: {
    languageId: {
      handler(val) {
        this.getPageData();
      },
    },
  },
  async created() {
    await this.getPageData();
  },
  methods: {
    async getPageData() {
      let _this = this;
      _this.showOverlay = true;

      let dynamicPagesGetBy = new DynamicPagesGetByType(_this);
      dynamicPagesGetBy.setParams({
        type: 2,
        LanguageId:_this.languageId
      })
      await dynamicPagesGetBy.fetch(function (content) {
        _this.showOverlay = false;
        _this.pageData = content.data;
        _this.pageData.value = JSON.parse(_this.pageData.value);
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async updateFooter() {
      let _this = this;
      _this.showOverlay = true;

      let updateDynamicPages = new UpdateDynamicPages(_this);
      // _this.pageData.value['address'] = '';
      _this.pageData.value = JSON.stringify(_this.pageData.value);
      updateDynamicPages.setParams(_this.pageData);
      await updateDynamicPages.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getPageData();
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    setLanguage(language) {
      this.languageId = language.languageId;
    },
    setEditorContent(content) {
      this.pageData.value.customFooter = content;
    },
    setEditorContentMobile(content) {
      this.pageData.value.customFooterMobile = content;
    },
  },
  components: {
    LanguagePicker,
    AddEditDeleteButton,
    ValidationProvider,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
    BFormTextarea,
  },
}
</script>

<style scoped>

</style>
